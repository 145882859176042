import * as React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import BlocContent from "../components/elements/bloc-content";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import BlocVideo from "../components/elements/bloc-video";
import Player from "../components/elements/player";

const AgencyPage = ({ data }) => {
  console.log(data.strapiNight.Video);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiNight.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiNight.SEO.metaDescription}
        />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra uppercase"
            content={data.strapiNight.Title}
          />
        </div>

        <BlocContent
          className="text-2xl relative z-30 max-w-screen-md p-10"
          content={data.strapiNight.Content.data.Content}
        />

        <div className="h-1/2 pb-8 xl:pb-10">
          {data.strapiNight.Video ? (
            <BlocVideo
              src={data.strapiNight.Video[0].url}
              alt={data.strapiNight.Video[0].alt}
              width="1525"
              height="825"
              controls={true}
            />
          ) : (
            <Player urlVideo={data.strapiNight.urlVideo} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PopNight {
    strapiNight {
      Content {
        data {
          Content
        }
      }
      Title
      urlVideo
      SEO {
        metaDescription
        metaTitle
      }
      ImgBackgroundVisible
      ImageBackground {
        url
      }
      Video {
        url
      }
    }
  }
`;
export default AgencyPage;
